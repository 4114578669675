<template>
    <el-upload v-loading="uploading > 0" class="upload-demo" :drag="true" :data="{userId: username}" :multiple="true" list-type="picture" :action="myAction" :on-change="handleChange" :on-success="handleSuccess" :on-remove="handleDelete" :file-list="initialFiles">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Drop file here or
            <em>click to upload</em>
        </div>
        <div slot="tip" class="el-upload__tip">any file with a size less than 2048kb</div>
    </el-upload>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
    props: [
        "action",
        "initialFiles"
    ],
    data() {
        return {

            myAction: '',
            uploading: 0
        }
    },
    created() {
        if (!this.action) {
            this.myAction = '/file-upload'
        } else {
            this.myAction = this.action
        }
    },
    computed: {
        username: function() {
            return firebase.auth().currentUser && firebase.auth().currentUser.uid || null
        }
    },
    methods: {

        handleSuccess(response, file, fileList) {
            console.log('successs')
            console.log(response)
            console.log(file)
            console.log(fileList)
            this.fileList = fileList
            this.$emit('fileSelected', this.fileList)
            this.uploading = 0
        },
        handleChange(file, fileList) {
            // this.fileList3 = fileList.slice(-3);
            console.log('change')
            console.log(file)
            console.log(fileList)
            if (file && file.status != 'success') {
                this.uploading = this.uploading + 1
                this.$emit('fileUploading', fileList)
            }
            this.fileList = fileList
        },
        handleDelete(file, fileList) {
            console.log('delete')
            console.log(file)
            console.log(fileList)
            this.fileList = fileList
            this.$emit('fileChanged', fileList)
        }
    }

}
</script>
<style scoped>

</style>
