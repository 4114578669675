<template>
    <div class="picker-wrap">
        <el-upload v-loading="avatarUploading" class="avatar-uploader" :data="{userId: username}" :action="myUploadServer" :on-change="avatarSelect" list-type="picture-card" :show-file-list="false" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess">
            <img v-if="thumbnail" :src="thumbnail" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <br>
        </el-upload>
        <el-button size="mini" class="delete-button" icon="el-icon-delete" type="danger" @click="deleteImage" style="position: absolute; right: 5px; top: 5px; z-index:99" v-if="thumbnail != null"></el-button>
    </div>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
let storage, uploadFolder


export default {
    props: [
        "uploadServer",
        "uploadFolder",
        "index",
        "width",
        "height",
        "src"
    ],
    mounted() {
        const folder = this.uploadFolder || 'library/projects'
        storage = firebase.storage()
        uploadFolder = storage.ref(folder)
        const _this = this
        // firebase.auth().currentUser.getToken(/* forceRefresh */ true).then(function(idToken) {
        // _this.token = idToken
        // })
    },
    data() {
        return {
            myUploadServer: this.uploadServer || '/file-upload',
            imageUrl: this.src || '',
            avatarUploading: false,
            key: this.index || null,
            token: ''
        }
    },
    computed: {
        thumbnail: function() {
            if (this.imageUrl != '') return this.imageUrl;
            return this.src
        },
        username: function() {
            return firebase.auth().currentUser && firebase.auth().currentUser.uid || null
        }
    },
    methods: {
        deleteImage() {
            this.imageUrl = null
            this.avatarUploading = false
            if (this.key)
                this.$emit('image', null, this.key)
            else
                this.$emit('image')
        },
        handleAvatarSuccess(res, file) {
            console.log(file)
            console.log(res)
            const _this = this
            if (file.status === 'success') {
                _this.avatarUploading = false;
                return new Promise(function(resolve, reject) {
                    var img = new Image();
                    img.src = res.metadata.mediaLink;
                    img.onload = function() {
                        try {
                            var width = img.naturalWidth,
                                height = img.naturalHeight;

                            // window.URL.revokeObjectURL(img.src);
                            if (width === _this.width && height === _this.height) {
                                console.log('File test ok')
                                _this.imageUrl = res.metadata.mediaLink
                                if (_this.key) {
                                    _this.$emit('image', { url: _this.imageUrl, name: file.name, bucket: res.bucket.id, fullPath: res.name }, _this.key)
                                } else {
                                    _this.$emit('image', { url: _this.imageUrl, name: file.name, bucket: res.bucket.id, fullPath: res.name })
                                }
                                resolve()
                            }
                            else {
                                //fail
                                console.log("Failed size test")
                                _this.$nextTick(function() {
                                    _this.$message("Image must be " + _this.width + "x" + _this.height)
                                })
                                resolve()
                            }

                        } catch (error) {
                            console.log(error)
                            resolve()
                        }

                    };
                });

            }

            // console.log('success')
        },
        beforeAvatarUpload(file) {
            const _this = this
            const isJPG = file.type === 'image/jpeg'
            const isPNG = file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 2

            if (!isJPG && !isPNG) {
                this.$message.error('Avatar picture must be JPG or PNG format!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('Avatar picture size can not exceed 2MB!');
                return false
            }
            this.avatarUploading = true
            return true
        },
        avatarSelect(file, fileList) {
            if (this.avatarUploading) return;
            return
            console.log(file)
            const _this = this;

            if (file) {
                var img = new Image();

                img.src = file.url;

                img.onload = function() {
                    var width = img.naturalWidth,
                        height = img.naturalHeight;

                    window.URL.revokeObjectURL(img.src);

                    if (width == _this.width && height == _this.height) {
                        return true
                    }
                    else {
                        //fail
                        console.log("Failed size test")
                        _this.$message("Image must be " + _this.width + "x" + _this.height)
                        return false
                    }
                };
            }

        },
    }
}
</script>
<style scoped>
.avatar-uploader.el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar {
    width: 128px;
    padding: 6px;
}

.picker-wrap {
    position: relative;
    width: 150px;
}



.delete-button {
    width: 32px;
    padding-left: 10px;
}























/* .avatar-uploader .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    padding: 12px;
    border: 1px solid #dedede;
    width: 100%;
    width: 178px;
    height: 178px;
    display: block;
} */
</style>
